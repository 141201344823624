import React, { useEffect, useState } from 'react';
import '../../styles/css/main.css';
import '../../styles/css/sections.css';
import Header from '../homepage/sections/Header';
import ContactUsSection from '../homepage/sections/ContactUsSection';
import Footer from '../homepage/sections/Footer';
import { Image, Stack, Container, Card } from 'react-bootstrap';

import backArrow from '../../assets/icons/back_arrow.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { Member, Project } from '../../models/Projects';
import {
  getAgendaProjectDetails,
  getAllProjects,
} from '../../services/Visitor';
import ProjectsTable from '../../utils/ProjectsTable';
import MembersModal from '../../utils/MembersModal';
import ProjectDetailsModal from '../../utils/ProjectDetailsModal';

const ProjectDetails = () => {
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [showProjectDetailsModal, setShowProjectDetailsModal] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [members, setMembers] = useState<Member[]>([]);
  const [projectDetails, setProjectDetails] = useState<Project | null>(null);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const handleMembersModalClose = () => setShowMembersModal(false);
  const handleMembersClick = (projectId: number) => {
    const filteredMembers =
      projects.find((project) => project.id === projectId)?.assigned_users ||
      [];
    setMembers(filteredMembers);
    setShowMembersModal(true);
  };
  const handleProjectDetailsModalClose = () =>
    setShowProjectDetailsModal(false);
  const handleProjectDetailsClick = async (projectId: number) => {
    try {
      if (id) {
        const resp = await getAgendaProjectDetails(projectId);
        setProjectDetails(resp);
        setShowProjectDetailsModal(true);
      }
    } catch (error) {}
  };

  const fetchAllProjects = async () => {
    try {
      if (id) {
        const resp = await getAllProjects(id);
        setProjects(resp.data.items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNaviagte = () => {
    navigate(`/projects`);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAllProjects();
  }, []);

  return (
    <>
      <Header />
      <section className="primary hero-section">
        <Container>
          <Stack gap={2} direction="horizontal" className="mb-4">
            <Image src={backArrow} onClick={handleNaviagte} />{' '}
            <h5 className="m-0">Projects</h5>
          </Stack>
          {projects.length > 0 ? (
            <ProjectsTable
              projects={projects}
              handleProjectDetailsClick={handleProjectDetailsClick}
              handleMembersClick={handleMembersClick}
              showBuyColumn={false}
            />
          ) : (
            <Card>
              <Card.Body className="pt-1 pb-1">No Projects</Card.Body>
            </Card>
          )}
        </Container>
      </section>

      {/* Members Modal */}
      <MembersModal
        show={showMembersModal}
        onClose={handleMembersModalClose}
        members={members}
      />

      <ProjectDetailsModal
        show={showProjectDetailsModal}
        onClose={handleProjectDetailsModalClose}
        projectDetails={projectDetails}
      />

      <ContactUsSection />
      <Footer />
    </>
  );
};

export default ProjectDetails;
