import React, { useState } from 'react';
import '../../styles/css/main.css';
import '../../styles/css/sections.css';
import { Container, Tabs, Tab } from 'react-bootstrap';

import Header from '../homepage/sections/Header';
import ContactUsSection from '../homepage/sections/ContactUsSection';
import Footer from '../homepage/sections/Footer';
import Myprojects from './Myprojects';

const ImpactorHomepage = () => {
  const [activeTab, setActiveTab] = useState<string>('Dashboard');
  const handleTabSelect = (key: string | null) => {
    if (key) {
      setActiveTab(key);
    }
  };
  return (
    <>
      <Header />
      <section className="primary hero-section">
        <Container>
          <Tabs
            id="uncontrolled-tab-example"
            className="mb-5 tabs-wrapper"
            activeKey={activeTab}
            onSelect={handleTabSelect}
          >
            <Tab eventKey="Dashboard" title="Dashboard"></Tab>

            <Tab eventKey="MyProjects" title="My Projects">
              <Myprojects activeTab={activeTab} />
            </Tab>
          </Tabs>
        </Container>
      </section>

      <ContactUsSection />
      <Footer />
    </>
  );
};

export default ImpactorHomepage;
