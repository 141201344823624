import React from 'react';
import { Modal, Button, Card, Stack, Image, Badge } from 'react-bootstrap';
import { Member } from '../models/Projects';
import userIcon1 from '../assets/images/avatars1.svg';

interface MembersModalProps {
  show: boolean;
  onClose: () => void;
  members: Member[];
}

const MembersModal: React.FC<MembersModalProps> = ({
  show,
  onClose,
  members,
}) => {
  return (
    <Modal show={show} onHide={onClose} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3 className="text-dark m-0 fw-bold">Members in Project</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="align-items-center">
        <Stack direction="vertical" gap={3} className="mb-2">
          {members.length > 0 ? (
            members.map((member) => (
              <Card key={member.id} className="rounded-3">
                <Card.Body className="p-2">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="justify-content-between pb-1"
                  >
                    <Stack direction="horizontal" gap={2}>
                      <Image
                        src={member.avatar || userIcon1} // Default to a fallback icon if avatar is missing
                        alt="user-icon"
                        className="global-user-thumb-large"
                      />
                      <Stack direction="vertical" gap={2}>
                        {member.name}
                        <Stack direction="horizontal" gap={2}>
                          <Badge
                            pill
                            bg="light"
                            text="dark"
                            className={
                              member.role.name === 'Hero' ? 'red' : 'green'
                            }
                          >
                            {member.role.name}
                          </Badge>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Card.Body>
              </Card>
            ))
          ) : (
            <p className="text-center">No members available.</p>
          )}
        </Stack>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <Button
          onClick={onClose}
          variant="outline-dark"
          className="d-flex align-items-center p-2 px-3"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MembersModal;
