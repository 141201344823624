import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import AuthGuard from './guards/AuthGuard';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Layout from './components/homepage/layout/Layout';
import { NotFoundPage } from './components/NotFound/NotFoundPage';
import Projects from './components/Projects/Projects';
import RoleGuard from './guards/RoleGuard';
import Heroes from './components/Heroes/Heroes';
import LayoutHeroDetails from './components/Heroes/LayoutHeroDetails';
import ProjectDetails from './components/Projects/ProjectDetails';
import ImpactorHomepage from './components/impactorHomepage/ImpactorHomepage';
import { Roles } from './models/Roles';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Layout />} />
          {/* <Route path="/forgot-password" element={<ForgotPwdPage />} />
          <Route path="/reset-password" element={<ResetPwdPage />} /> */}
          <Route
            path="/projects"
            element={
              // <AuthGuard>
              //   <RoleGuard allowedRoles={[Roles.Impactor]}>
              <Projects />
              //   </RoleGuard>
              // </AuthGuard>
            }
          />
          <Route
            path="/projectDetails/:id"
            element={
              // <AuthGuard>
              //   <RoleGuard allowedRoles={[Roles.Impactor]}>
              <ProjectDetails />
              //   </RoleGuard>
              // </AuthGuard>
            }
          />
          <Route
            path="/heroes"
            element={
              // <AuthGuard>
              //   <RoleGuard allowedRoles={[Roles.Impactor]}>
              <Heroes />
              //   </RoleGuard>
              // </AuthGuard>
            }
          />
          <Route
            path="/herodetails/:id"
            element={
              // <AuthGuard>
              //   <RoleGuard allowedRoles={[Roles.Impactor]}>
              <LayoutHeroDetails />
              //   </RoleGuard>
              // </AuthGuard>
            }
          />
          <Route
            path="/impactor"
            element={
              <AuthGuard>
                <RoleGuard allowedRoles={[Roles.Impactor]}>
                  <ImpactorHomepage />
                </RoleGuard>
              </AuthGuard>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
