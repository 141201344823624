import axiosInstance from '../axiosInstance'; // Import the configured axios instance
import { Project, ProjectDetailsResponse } from '../models/Projects';
import { ENDPOINTS } from './endpoints';

export const getAllImpactorProjects = async (): Promise<
  ProjectDetailsResponse<Project>
> => {
  return axiosInstance
    .get(`${ENDPOINTS.PROJECTLISTS}/?page=1`)
    .then((res) => res.data);
};
