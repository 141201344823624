import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  ListGroup,
  Row,
  Stack,
  Image,
} from 'react-bootstrap';

import emailIcon from '../../assets/icons/email-icon.svg';
import arrowIcon from '../../assets/icons/arrow-icon.svg';
import userAvatar1 from '../../assets/images/user_avatar_image.png';
import { useParams } from 'react-router-dom';
import { Member, Project } from '../../models/Projects';
import { Hereos } from '../../models/Homepage';
import { getHeroProjects, getProjectDetails } from '../../services/Visitor';
import ProjectsTable from '../../utils/ProjectsTable';
import MembersModal from '../../utils/MembersModal';
import ProjectDetailsModal from '../../utils/ProjectDetailsModal';

function HeroDetails() {
  const [projectDetails, setProjectDetails] = useState<Project | null>(null);
  const [heroDetails, setHeroDetails] = useState<Hereos | null>(null);
  const [projects, setProjects] = useState<Project[]>([]);
  const [showProjectDetailsModal, setShowProjectDetailsModal] = useState(false);
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [members, setMembers] = useState<Member[]>([]);

  const { id } = useParams<{ id: string }>();
  const fetchHeroDetails = async () => {
    try {
      if (id) {
        const resp = await getHeroProjects(id);
        setHeroDetails(resp.data.heroDetail);
        setProjects(resp.data.items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleProjectDetailsClick = async (projectId: number) => {
    try {
      if (id) {
        const resp = await getProjectDetails(id, projectId);
        setProjectDetails(resp);
        setShowProjectDetailsModal(true);
      }
    } catch (error) {}
  };

  const handleMembersClick = (projectId: number) => {
    const filteredMembers =
      projects.find((project) => project.id === projectId)?.assigned_users ||
      [];
    setMembers(filteredMembers);
    setShowMembersModal(true);
  };
  const handleMembersModalClose = () => setShowMembersModal(false);

  const handleProjectDetailsModalClose = () =>
    setShowProjectDetailsModal(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchHeroDetails();
  }, []);
  return (
    <Row>
      <Col md={4}>
        <Card className="pt-1 pb-1 border-0 rounded-3">
          <Card.Body>
            <Card.Title className="pb-3">Profile</Card.Title>

            <Stack
              direction="vertical"
              className="align-items-center mb-4"
              gap={1}
            >
              <span
                className="rounded-circle bg-light"
                style={{ height: '104px', width: '104px', overflow: 'hidden' }}
              >
                <Image src={userAvatar1} fluid />
              </span>

              <h5 className="mb-1">{heroDetails?.name}</h5>
            </Stack>

            <ListGroup>
              <ListGroup.Item className="border-0">
                <Stack
                  direction="horizontal"
                  className="align-items-start border-bottom pb-1"
                >
                  <Image src={emailIcon} alt="Image" className="me-2" />{' '}
                  <Stack direction="vertical">
                    <h6 className="mb-1">Gender</h6>
                    <span>{heroDetails?.gender}</span>
                  </Stack>
                </Stack>
              </ListGroup.Item>
              <ListGroup.Item className="border-0">
                <Stack
                  direction="horizontal"
                  className="align-items-start border-bottom pb-1"
                >
                  <Image src={emailIcon} alt="Image" className="me-2" />{' '}
                  <Stack direction="vertical">
                    <h6 className="mb-1">Email</h6>
                    <span>{heroDetails?.email}</span>
                  </Stack>
                </Stack>
              </ListGroup.Item>

              <Button size="lg" className="custom-border" variant="outline">
                CHAT
                <Image
                  src={arrowIcon}
                  alt="Impactivator Btn"
                  className="mx-2"
                />
              </Button>
            </ListGroup>
          </Card.Body>
        </Card>
      </Col>
      <Col md={8}>
        <ProjectsTable
          projects={projects}
          handleProjectDetailsClick={handleProjectDetailsClick}
          handleMembersClick={handleMembersClick}
        />
        <MembersModal
          show={showMembersModal}
          onClose={handleMembersModalClose}
          members={members}
        />

        <ProjectDetailsModal
          show={showProjectDetailsModal}
          onClose={handleProjectDetailsModalClose}
          projectDetails={projectDetails}
        />
      </Col>
    </Row>
  );
}
export default HeroDetails;
