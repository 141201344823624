import axiosInstance from '../axiosInstance'; // Import the configured axios instance
import { HeroesDetailsResponse, Project } from '../models/Projects';
import { ENDPOINTS } from './endpoints';

export const getHeroProjects = async (
  id: string
): Promise<HeroesDetailsResponse<Project>> => {
  return axiosInstance
    .get(`${ENDPOINTS.HERODETAILS}/${id}?page=1`)
    .then((res) => res.data);
};

export const getProjectDetails = async (
  heroId: string,
  projectId: number
): Promise<Project> => {
  return axiosInstance
    .get(`${ENDPOINTS.PROJECTDETAILS}/${heroId}/${projectId}`)
    .then((res) => res.data.data);
};

export const getAllProjects = async (
  themeId: string
): Promise<HeroesDetailsResponse<Project>> => {
  return axiosInstance
    .get(`${ENDPOINTS.PROJECTLISTS}/${themeId}?page=1`)
    .then((res) => res.data);
};

export const getAgendaProjectDetails = async (
  projectId: number
): Promise<Project> => {
  return axiosInstance
    .get(`${ENDPOINTS.AGENDACARD}/${projectId}`)
    .then((res) => res.data.data);
};
