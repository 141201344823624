import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Image, Card } from 'react-bootstrap';
import arrowIcon from '../../../assets/icons/arrow-icon.svg';
import userAvatar1 from '../../../assets/images/user_avatar_image.png';
import userAvatar2 from '../../../assets/images/user_avatar_image-2.png';
import userAvatar3 from '../../../assets/images/user_avatar_image-3.png';
import userAvatar4 from '../../../assets/images/user_avatar_image-4.png';
import userAvatar5 from '../../../assets/images/user_avatar_image-5.png';
import starticon from '../../../assets/icons/staricon.svg';
import { avatarData } from '../../../services/Homepage';
import { useNavigate } from 'react-router-dom';
import { Hereos } from '../../../models/Homepage';

function AvatarSection() {
  const [hereos, setHereos] = useState<Hereos[]>([]);

  const navigate = useNavigate();
  const avatar_Data = async () => {
    try {
      const resp = await avatarData();
      const avatars = [
        userAvatar1,
        userAvatar2,
        userAvatar3,
        userAvatar4,
        userAvatar5,
      ];
      const dataWithImages = resp.data.map((hero, index) => ({
        ...hero,
        avatar: avatars[index % avatars.length],
      }));

      setHereos(dataWithImages);
    } catch (error) {
      console.log(error);
    }
  };

  const handleHeroClick = (id: number) => {
    navigate(`/herodetails/${id}`);
  };
  const handleNavigate = () => {
    navigate('/heroes');
  };
  useEffect(() => {
    avatar_Data();
  }, []);
  return (
    <section className="tertiary avatars-section" id="marketplace">
      <Container>
        <Row className="justify-content-start mx-1 mx-md-3">
          <Col sm={12}>
            <div className="d-flex justify-content-between align-items-center">
              <span className="hero-title-medium">
                OUR HEROeS & THEIR UNIQUE NFTS
              </span>
              <Button
                size="lg"
                variant="outline-dark"
                className="d-flex align-items-center"
                onClick={handleNavigate}
              >
                More
                <Image src={arrowIcon} alt="Hero Btn" className="mx-2" />
              </Button>
            </div>
            <h6 className="section-title mt-3 mb-3">
              Meet our heroes dedicated to tackling UN SDG-aligned challenges
              with resilience and innovation, driving positive change in their
              communities.
            </h6>
          </Col>
          <Col sm={12}>
            <Row>
              {hereos.length > 0 &&
                hereos.slice(0, 5).map((hero) => (
                  <Col
                    onClick={() => handleHeroClick(hero.id)}
                    key={hero.id}
                    className="media-break mb-2"
                    style={{ flex: 'initial' }}
                  >
                    <Card
                      style={{
                        backgroundColor: '#000',
                      }}
                    >
                      <Image
                        src={starticon}
                        style={{
                          right: '0.5rem',
                          top: '0.5rem',
                          position: 'absolute',
                          cursor: 'pointer',
                        }}
                      />
                      <Card.Img src={hero.avatar} alt="Card image" />
                      <Card.ImgOverlay
                        style={{
                          background:
                            'linear-gradient(0deg, rgba(16, 19, 31, 0.9) 0%, rgba(16, 19, 31, 0) 100%)',
                          marginTop: '150px',
                        }}
                      >
                        <div
                          style={{
                            bottom: '1.5rem',
                            position: 'absolute',
                          }}
                        >
                          <Card.Text className="text-white text-title-ul">
                            {hero.name}
                          </Card.Text>
                        </div>
                      </Card.ImgOverlay>
                    </Card>
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AvatarSection;
