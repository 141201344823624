import axiosInstance from '../axiosInstance'; // Import the configured axios instance
import { ENDPOINTS } from './endpoints';

interface paymentResponse {
  statusCode: number;
  message: string;
  data: {
    payment_id: string;
    clientSecret: string;
  };
}

export const payment = async (projectId: number): Promise<paymentResponse> => {
  return axiosInstance
    .post(`${ENDPOINTS.PAYMENT}`, {
      project_id: projectId,
      amount: 1000,
      currency: 'usd',
    })
    .then((res) => res.data);
};

export const paymentSucces = async (
  payment_identifier: string,
  response: any
): Promise<paymentResponse> => {
  return axiosInstance
    .post(`${ENDPOINTS.PAYMENTSUCCESS}`, {
      payment_identifier,
      response,
    })
    .then((res) => res.data);
};
