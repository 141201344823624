import React from 'react';
import { Offcanvas, Button, Card, Stack, Image } from 'react-bootstrap';
import { Project } from '../models/Projects';
import rocketIcon from '../assets/icons/rocket.svg';
import clockIcon from '../assets/icons/clock-icon.svg';
interface ProjectDetailsModalProps {
  show: boolean;
  onClose: () => void;
  projectDetails: Project | null; // Adjust if your project details include more fields
}

const ProjectDetailsModal: React.FC<ProjectDetailsModalProps> = ({
  show,
  onClose,
  projectDetails,
}) => {
  if (!projectDetails) {
    return null; // or render a message like "No details available"
  }
  return (
    <Offcanvas show={show} onHide={onClose} placement="end">
      <Offcanvas.Header className="border-bottom" closeButton>
        <Offcanvas.Title className="heading">
          <h3 className="text-dark m-0 fw-bold">Project Details</h3>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Card className="bg-grey mb-3 rounded-3 border-0">
          <Card.Body>
            <Stack direction="horizontal" gap={3}>
              <Image
                src={rocketIcon} // Default to a fallback icon if image is missing
                alt="rocket-icon"
                className="global-user-thumb"
              />
              <h6 className="m-0">{projectDetails.name}</h6>
            </Stack>
          </Card.Body>
        </Card>
        <Stack direction="horizontal" gap={5} className="mb-2">
          <span className="d-inline-flex align-items-center gap-2">
            <Image src={clockIcon} alt="clock-icon" />
            Created On
          </span>
          <span>{projectDetails.created_at}</span>
        </Stack>
        <Stack direction="vertical" gap={3} className="mb-2 mt-3">
          <Card className="rounded-3">
            <Card.Body>
              <h6 className="fw-sb">Project Impact</h6>
              <p className="m-0">{projectDetails.impact}</p>
            </Card.Body>
          </Card>
          <Card className="rounded-3">
            <Card.Body>
              <h6 className="fw-sb">Project Theme</h6>
              <p className="m-0">{projectDetails.theme}</p>
            </Card.Body>
          </Card>
          <Card className="rounded-3">
            <Card.Body>
              <h6 className="fw-sb">Description</h6>
              <p className="m-0">{projectDetails.description}</p>
            </Card.Body>
          </Card>
        </Stack>
      </Offcanvas.Body>
      <Offcanvas.Header className="border-top justify-content-end">
        <Button
          variant="outline-dark"
          className="d-flex align-items-center p-2 px-3"
          onClick={onClose}
        >
          Close
        </Button>
      </Offcanvas.Header>
    </Offcanvas>
  );
};

export default ProjectDetailsModal;
