import React, { useEffect, useState } from 'react';
import '../../styles/css/main.css';
import '../../styles/css/sections.css';
import { Col, Container, Row, Image } from 'react-bootstrap';
import Header from '../homepage/sections/Header';
import ContactUsSection from '../homepage/sections/ContactUsSection';
import Footer from '../homepage/sections/Footer';
import heroBanner from '../../assets/images/hero_avatars.png';
import { useNavigate } from 'react-router-dom';
import { agendaCardData } from '../../services/Homepage';
import { AgendaCard } from '../../models/Homepage';

const Projects = () => {
  const [agenda, setAgenda] = useState<AgendaCard[]>([]);

  const navigate = useNavigate();

  const getAgendaData = async () => {
    try {
      const resp = await agendaCardData();
      setAgenda(resp.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleNaviagte = (id: string) => {
    navigate(`/projectDetails/${id}`);
  };
  useEffect(() => {
    getAgendaData();
  }, []);
  return (
    <>
      <Header />
      <section className="primary hero-section">
        <Container>
          <Row className="mx-1 mx-md-5 ">
            <Col
              md={12}
              lg={7}
              className="text-center text-lg-start d-flex flex-column justify-content-center"
            >
              <h3 className="display-1 font-brand line-0">REVOLUTIONISING</h3>
              <h3 className="display-1 font-brand line-0">SOCIAL IMPACT</h3>
            </Col>
            <Col
              md={12}
              lg={5}
              className="text-center text-lg-start mt-5 mt-lg-0"
            >
              <Image src={heroBanner} alt="hero" fluid />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="secondary about-us-section">
        <Container>
          <Row className="d-flex justify-content-center mx-1 mx-md-3">
            <Col xs={12} className="text-center mb-3">
              <h6 className="section-title">
                <span className="text-muted">A </span>
                <span className="text-dark fw-bold">
                  DECENTRALISED ONLINE MARKETPLACE
                </span>
                <span className="text-muted"> CONNECTING </span>
                <span className="text-dark fw-bold">SOCIAL ENTREPRENEURS </span>
                <span className="text-muted">
                  IN DEVELOPING COUNTRIES WITH{' '}
                </span>
                <span className="text-dark fw-bold">INTERNATIONAL CLIENTS</span>
              </h6>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center m-1 m-md-3">
            {agenda.length > 0 &&
              agenda.map((item, index) => (
                <Col
                  xs={4}
                  sm={3}
                  md={2}
                  key={index}
                  onClick={() => handleNaviagte(item.id)}
                >
                  <Image src={item.url} alt="sdg" className="img-fluid m-2" />
                </Col>
              ))}
          </Row>
        </Container>
      </section>
      <ContactUsSection />
      <Footer />
    </>
  );
};

export default Projects;
