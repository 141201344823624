// src/components/CheckoutForm.tsx
import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { paymentSucces } from '../../services/payment';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';

interface CheckoutFormProps {
  clientSecret: string;
  handleSignUpClose: () => void;
  paymentIdentifier: string;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  clientSecret,
  handleSignUpClose,
  paymentIdentifier,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState<string | null>(null);
  const cardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        fontSize: '18px',
        color: '#333',
        '::placeholder': {
          color: '#888',
        },
        padding: '16px',
        minHeight: '56px',
        borderRadius: '8px',
        border: '1px solid #ddd',
        backgroundColor: '#f9f9f9',
      },
      invalid: {
        color: '#dc3545',
        iconColor: '#dc3545',
      },
      complete: {
        color: '#28a745',
        iconColor: '#28a745',
      },
      empty: {
        color: '#999',
      },
    },
  };
  const formStyles: React.CSSProperties = {
    maxWidth: '400px',
    margin: 'auto',
    padding: '20px',
    borderRadius: '8px',
  };

  const buttonStyles: React.CSSProperties = {
    marginTop: '1rem',
    width: '100%',
    padding: '12px',
    fontSize: '16px',
    color: '#ffffff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };

  const errorStyles: React.CSSProperties = {
    color: '#dc3545',
    marginTop: '10px',
    textAlign: 'center',
  };
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setIsProcessing(true);
    setPaymentError(null);

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) return;

    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: cardElement,
        },
      }
    );

    if (error) {
      setPaymentError(error.message ?? 'An error occurred');
      setIsProcessing(false);
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      // Handle successful payment here (e.g., show confirmation to user)
      handleSucces(paymentIdentifier, paymentIntent);
      handleSignUpClose();
    }
  };

  const handleSucces = async (payment_identifier: string, response: any) => {
    try {
      const resp = await paymentSucces(payment_identifier, response);
      toast.dismiss();
      toast.success(`${resp?.message}`, {
        position: 'top-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch (error) {
      if (isAxiosError(error) && error?.response?.data?.message) {
        toast.dismiss();
        toast.error(`${error?.response?.data?.message}`, {
          position: 'top-right',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    }
  };
  return (
    <form onSubmit={handleSubmit} style={formStyles}>
      <CardElement options={cardElementOptions} />
      <Button
        type="submit"
        disabled={!stripe || isProcessing}
        style={buttonStyles}
      >
        {isProcessing ? 'Processing...' : 'Pay'}
      </Button>
      {paymentError && <div style={errorStyles}>{paymentError}</div>}
    </form>
  );
};

export default CheckoutForm;
