import React, { useState } from 'react';
import { Card, Table, Button, Image, Stack, Modal } from 'react-bootstrap';
import { Project } from '../models/Projects';
import eyeIcon from '../assets/icons/eye-icon.svg';
import sortIcon from '../assets/icons/sort-icon.svg';
import userIcon1 from '../assets/images/avatars1.svg';
import Loginform from './Loginform';
import { useSelector } from 'react-redux';
import { selectUserId } from '../redux/authSlice';
import { payment } from '../services/payment';
import CheckoutForm from '../components/payment/CheckoutForm';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

interface ProjectTableProps {
  projects: Project[];
  handleProjectDetailsClick: (projectId: number) => void; // Adjust based on how you handle project detail clicks
  handleMembersClick: (projectId: number) => void; // Adjust based on how you handle member clicks
  showBuyColumn?: boolean;
  activeTab?: string;
}

const ProjectTable: React.FC<ProjectTableProps> = ({
  projects,
  handleProjectDetailsClick,
  handleMembersClick,
  showBuyColumn = true,
  activeTab,
}) => {
  const [show, setShow] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const userId = useSelector(selectUserId);
  const [showSignUp, setShowSignUp] = useState(false);
  const [paymentIdentifier, setPaymentIdentifier] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSignUpClose = () => {
    setShowSignUp(false);
  };
  const handleSignUpShow = () => setShowSignUp(true);

  const handleBuy = async (projectId: number) => {
    if (userId === 0) {
      handleShow();
    } else {
      try {
        const resp = await payment(projectId);
        setClientSecret(resp.data.clientSecret);
        setPaymentIdentifier(resp.data.payment_id);
        if (resp.statusCode === 200) {
          handleSignUpShow();
        }
      } catch (error) {
        if (isAxiosError(error) && error?.response?.data?.message) {
          toast.dismiss();
          toast.error(`${error?.response?.data?.message}`, {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      }
    }
  };

  return (
    <>
      <Card>
        <Card.Body className="pt-1 pb-1">
          <Table responsive striped hover>
            <thead>
              <tr className="align-middle">
                <th>
                  <div className="d-flex justify-content-between align-items-center">
                    Project Name
                    <Button
                      variant="link"
                      className="icon-btn p-0"
                      title="sort"
                    >
                      <Image src={sortIcon} alt="sort" />
                    </Button>
                  </div>
                </th>
                <th>
                  <div className="d-flex justify-content-between align-items-center">
                    Project Theme
                    <Button
                      variant="link"
                      className="icon-btn p-0"
                      title="sort"
                    >
                      <Image src={sortIcon} alt="sort" />
                    </Button>
                  </div>
                </th>
                <th>
                  <div className="d-flex justify-content-between align-items-center">
                    Members
                    <Button
                      variant="link"
                      className="icon-btn p-0"
                      title="view members"
                    >
                      <Image src={sortIcon} alt="sort" />
                    </Button>
                  </div>
                </th>
                <th className="text-center">Actions</th>
                {showBuyColumn && <th className="text-center">Buy</th>}
              </tr>
            </thead>
            <tbody>
              {projects.map((project) => (
                <tr className="align-middle" key={project.id}>
                  <td>{project.name}</td>
                  <td>{project.theme}</td>
                  <td>
                    <div
                      className="members-group-stack d-flex align-items-center"
                      onClick={() => handleMembersClick(project.id)}
                    >
                      {project.assigned_users?.slice(0, 3).map((member) => (
                        <Image
                          key={member.id}
                          src={member.avatar || userIcon1} // Default avatar if not available
                          alt="user"
                          className="table-user"
                        />
                      ))}
                      {project.assigned_users &&
                        project.assigned_users.length > 3 && (
                          <span className="table-user members-count d-inline-block rounded-circle d-inline-flex align-items-center justify-content-center">
                            +{project.assigned_users.length - 3}
                          </span>
                        )}
                    </div>
                  </td>
                  <td className="text-center">
                    <Stack
                      direction="horizontal"
                      className="justify-content-center"
                    >
                      <Button
                        variant="link"
                        className="icon-btn p-0 m-0"
                        title="View"
                        onClick={() => handleProjectDetailsClick(project.id)}
                      >
                        <Image src={eyeIcon} alt="view" />
                      </Button>
                    </Stack>
                  </td>
                  {showBuyColumn && (
                    <td className="text-center">
                      <div>
                        <Button
                          variant="primary"
                          className="mx-2 custom-border"
                          onClick={() => handleBuy(project.id)}
                        >
                          {activeTab === 'MyProjects' ? 'BUY MORE' : 'BUY'}
                        </Button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal show={show} onHide={handleClose} scrollable>
        <Loginform handleClose={handleClose} />
        {/* <ForgotPassword /> */}
      </Modal>

      <Modal show={showSignUp} onHide={handleSignUpClose} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="text-dark m-0 fw-bold">Payment</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {clientSecret && (
            <CheckoutForm
              clientSecret={clientSecret}
              handleSignUpClose={handleSignUpClose}
              paymentIdentifier={paymentIdentifier}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProjectTable;
