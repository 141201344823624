import React, { useEffect, useState } from 'react';
import '../../styles/css/main.css';
import '../../styles/css/sections.css';
import Header from '../homepage/sections/Header';
import ContactUsSection from '../homepage/sections/ContactUsSection';
import Footer from '../homepage/sections/Footer';
import { Col, Container, Row, Image, Card } from 'react-bootstrap';

import heroBanner from '../../assets/images/hero_avatars.png';
import userAvatar1 from '../../assets/images/user_avatar_image.png';
import userAvatar2 from '../../assets/images/user_avatar_image-2.png';
import userAvatar3 from '../../assets/images/user_avatar_image-3.png';
import userAvatar4 from '../../assets/images/user_avatar_image-4.png';
import userAvatar5 from '../../assets/images/user_avatar_image-5.png';
import starticon from '../../assets/icons/staricon.svg';
import { useNavigate } from 'react-router-dom';
import { avatarData } from '../../services/Homepage';
import { Hereos } from '../../models/Homepage';
const Heroes = () => {
  const [hereos, setHereos] = useState<Hereos[]>([]);
  const navigate = useNavigate();

  const avatar_Data = async () => {
    try {
      const resp = await avatarData();
      const avatars = [
        userAvatar1,
        userAvatar2,
        userAvatar3,
        userAvatar4,
        userAvatar5,
      ];
      const dataWithImages = resp.data.map((hero, index) => ({
        ...hero,
        avatar: avatars[index % avatars.length],
      }));

      setHereos(dataWithImages);
    } catch (error) {
      console.log(error);
    }
  };

  const handleHeroClick = (id: number) => {
    navigate(`/herodetails/${id}`);
  };
  useEffect(() => {
    avatar_Data();
  }, []);
  return (
    <>
      <Header />
      <section className="primary hero-section">
        <Container>
          <Row className="mx-1 mx-md-5 ">
            <Col
              md={12}
              lg={7}
              className="text-center text-lg-start d-flex flex-column justify-content-center"
            >
              <h3 className="display-1 font-brand line-0">REVOLUTIONISING</h3>
              <h3 className="display-1 font-brand line-0">SOCIAL IMPACT</h3>
            </Col>
            <Col
              md={12}
              lg={5}
              className="text-center text-lg-start mt-5 mt-lg-0"
            >
              <Image src={heroBanner} alt="hero" fluid />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="secondary about-us-section">
        <Container>
          <Col sm={12} className="text-center">
            <h5 className="display-2 font-brand line-0">
              OUR HEROeS & THEIR UNIQUE NFTS
            </h5>
            <h6 className="mt-3 mb-3">
              Meet our heroes dedicated to tackling UN SDG-aligned challenges
              with resilience and innovation, driving positive change in their
              communities.
            </h6>
          </Col>

          <Row>
            {hereos.length > 0 &&
              hereos.map((hero) => (
                <Col
                  onClick={() => handleHeroClick(hero.id)}
                  key={hero.id}
                  className="media-break mb-2"
                  style={{ flex: 'initial' }}
                >
                  <Card
                    style={{
                      backgroundColor: '#000',
                    }}
                  >
                    <Image
                      src={starticon}
                      style={{
                        right: '0.5rem',
                        top: '0.5rem',
                        position: 'absolute',
                        cursor: 'pointer',
                      }}
                    />
                    <Card.Img src={hero.avatar} alt="Card image" />
                    <Card.ImgOverlay
                      style={{
                        background:
                          'linear-gradient(0deg, rgba(16, 19, 31, 0.9) 0%, rgba(16, 19, 31, 0) 100%)',
                        marginTop: '150px',
                      }}
                    >
                      <div
                        style={{
                          bottom: '1.5rem',
                          position: 'absolute',
                        }}
                      >
                        <Card.Text className="text-white text-title-ul">
                          {hero.name}
                        </Card.Text>
                      </div>
                    </Card.ImgOverlay>
                  </Card>
                </Col>
              ))}
          </Row>
        </Container>
      </section>
      <ContactUsSection />
      <Footer />
    </>
  );
};

export default Heroes;
