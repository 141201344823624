import React from 'react';
import HeroDetails from './HeroDetails';
import { Container, Stack, Image } from 'react-bootstrap';
import backArrow from '../../assets/icons/back_arrow.svg';
import Header from '../homepage/sections/Header';
import ContactUsSection from '../homepage/sections/ContactUsSection';
import Footer from '../homepage/sections/Footer';
import { useNavigate } from 'react-router-dom';
function LayoutHeroDetails() {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/heroes');
  };
  return (
    <>
      <Header />
      <section className="primary hero-section">
        <Container>
          <Stack gap={2} direction="horizontal" className="mb-4">
            <Image src={backArrow} onClick={handleNavigate} />
            <h5 className="m-0">Hero Details</h5>
          </Stack>
          <HeroDetails />
        </Container>
      </section>
      <ContactUsSection />
      <Footer />
    </>
  );
}

export default LayoutHeroDetails;
