import React, { useEffect, useState } from 'react';
import { Button, Image, Stack, Card, Col, Row, Form } from 'react-bootstrap';
import filterIcon from '../../assets/icons/filter-icon.svg';
import closeIcon from '../../assets/icons/close-icon.svg';
import { Link } from 'react-router-dom';
import { getAllImpactorProjects } from '../../services/impactorHomepage';
import { Member, Project } from '../../models/Projects';
import ProjectTable from '../../utils/ProjectsTable';
import MembersModal from '../../utils/MembersModal';
import ProjectDetailsModal from '../../utils/ProjectDetailsModal';
import { getAgendaProjectDetails } from '../../services/Visitor';

interface MyProjectsProps {
  activeTab: string;
}
const Myprojects: React.FC<MyProjectsProps> = ({ activeTab }) => {
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false);
  const [showProjectDetailsModal, setShowProjectDetailsModal] = useState(false);
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [members, setMembers] = useState<Member[]>([]);
  const [projectDetails, setProjectDetails] = useState<Project | null>(null);

  const handleMembersModalClose = () => setShowMembersModal(false);

  const handleProjectDetailsModalClose = () =>
    setShowProjectDetailsModal(false);
  const toggleFilterDiv = () => {
    setIsFilterActive(!isFilterActive);
  };
  const handleProjectDetailsClick = async (projectId: number) => {
    try {
      const resp = await getAgendaProjectDetails(projectId);
      setProjectDetails(resp);
      setShowProjectDetailsModal(true);
    } catch (error) {}
  };
  const handleMembersClick = (projectId: number) => {
    const filteredMembers =
      projects.find((project) => project.id === projectId)?.assigned_users ||
      [];
    setMembers(filteredMembers);
    setShowMembersModal(true);
  };

  const get_All_Projects = async () => {
    try {
      const resp = await getAllImpactorProjects();
      setProjects(resp.data.items);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (activeTab === 'MyProjects') {
      get_All_Projects();
    }
  }, [activeTab]);
  return (
    <>
      <Stack
        gap={2}
        direction="horizontal"
        className="mb-3 justify-content-between"
      >
        <h5 className="m-0">My Projects</h5>

        <Button variant="light" onClick={toggleFilterDiv}>
          {' '}
          <Image src={filterIcon} alt="filter" className="me-2" />
          Filters
        </Button>
      </Stack>
      {isFilterActive && (
        <Col sm={12}>
          <div className="card-wrapper-bg mb-3">
            <Card bg="light">
              <Card.Body className="p-3">
                <Card.Title className="pb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    {' '}
                    <span className="d-inline-flex justify-content-center align-items-center gap-2 fw-bold">
                      <Image src={filterIcon} alt="filter" />
                      Filters
                    </span>
                    <Link onClick={toggleFilterDiv} to={''}>
                      <Image src={closeIcon} alt="filter" />
                    </Link>
                  </div>
                </Card.Title>
                <Form id="filterForm">
                  <Row className="d-flex align-items-center">
                    <Col sm={6} md={4}>
                      <Form.Label htmlFor="project_name">
                        Project Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="project_name"
                        placeholder=""
                        className="mb-3"
                      />
                    </Col>
                    <Col sm={6} md={4}>
                      <Form.Label htmlFor="created_by">Created By</Form.Label>
                      <Form.Control
                        type="text"
                        id="created_by"
                        placeholder=""
                        className="mb-3"
                      />
                    </Col>
                    <Col sm={6} md={4}>
                      <Form.Label htmlFor="created_on">Created On</Form.Label>
                      <Form.Control
                        type="text"
                        id="created_on"
                        placeholder=""
                        className="mb-3"
                      />
                    </Col>
                    <Col sm={6} md={4}>
                      <Form.Label htmlFor="created_from">
                        Created From
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="created_from"
                        placeholder=""
                        className="mb-3"
                      />
                    </Col>
                    <Col sm={6} md={4}>
                      <Form.Label htmlFor="created_to">Created To</Form.Label>
                      <Form.Control
                        type="text"
                        id="created_to"
                        placeholder=""
                        className="mb-3"
                      />
                    </Col>

                    <Col sm={6} md={4}>
                      <div className="d-flex justify-content-start gap-2 mt-3">
                        <Button
                          className=" p-2 px-3"
                          variant="outline-dark"
                          type="button"
                          onClick={toggleFilterDiv}
                        >
                          Clear
                        </Button>
                        <Button
                          className=" p-2 px-3"
                          variant="success"
                          type="submit"
                        >
                          Search
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </Col>
      )}
      {projects.length > 0 ? (
        <ProjectTable
          projects={projects}
          handleProjectDetailsClick={handleProjectDetailsClick}
          handleMembersClick={handleMembersClick}
          showBuyColumn={true}
          activeTab={activeTab}
        />
      ) : (
        <Card>
          <Card.Body className="pt-1 pb-1">No Projects</Card.Body>
        </Card>
      )}

      {/* members modal */}
      <MembersModal
        show={showMembersModal}
        onClose={handleMembersModalClose}
        members={members}
      />

      <ProjectDetailsModal
        show={showProjectDetailsModal}
        onClose={handleProjectDetailsModalClose}
        projectDetails={projectDetails}
      />
    </>
  );
};

export default Myprojects;
